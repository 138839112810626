<template lang='pug'>
  .home-page(:class="[lang]")
    section.section
      div.partone
        div.header
          div.logo_area
            div.img_area
              img(src="../../assets/img/m/logo.png")
            div.title Answered!
          .area-right
            div.lanSwitch
              el-dropdown(@command="handleCommand", trigger="click")
                span.el-dropdown-link {{command}}
                    i.el-icon-arrow-down.el-icon--right
                el-dropdown-menu(slot="dropdown")
                    el-dropdown-item(command="Eng") Eng
                    el-dropdown-item(command="Indonesia") Indonesia
            .input-area(v-if="isShowInput")
              input.input(
                :placeholder="$TT('search')"
                maxlength='150'
                v-model="inputValue"
                autofocus="autofocus"
                @keyup.enter="unShowInput('homepage_search_enter')"
              )
              img.input-search_icon(src="../../assets/img/m/input-icon.png")
            .search-icon(@click="showInput" v-else)
              img(src="../../assets/img/m/search-icon.png")
        div.content
          .title(v-html="$TT('SNAP AND GET')")
          .title.sub(v-html="$TT('ANSWERS')")
          .details(v-html="$TT('details')")
          div(@click="download('download_googleplay_head_click')")
              div.google
                img(src="../../assets/img/m/google.png")
          div.partone_img
      div.parttwo
        div.content
          .title(v-html="$TT('p3_title')")
          .sub_title(v-html="$TT('p3_sub_title')")
          .details
            .number 3,000,000
            .smalltriangle
              img.spac1(src="../../assets/img/m/triangle1.png")
              img.spac2(src="../../assets/img/m/triangle2.png")
            .questions(v-html="$TT('questions')")
      div.partthree
        .title(v-html="$TT('pictOne_title')")
        .sub_title(v-html="$TT('pictOne_sub_title')")
        div.partthree_img
          img(src="../../assets/img/m/partthree_img.png")
        div.partThreePeople
          img(src="../../assets/img/m/partthree_people.png")
      div.partfour
        .title(v-html="$TT('pictTwo_title')")
        .sub_title(v-html="$TT('pictTwo_sub_title')")
        div.partfour_img
          div.partfour_imgone
            img(src="../../assets/img/m/partfour_imgone.png")
          div.partfour_imgtwo
            img(src="../../assets/img/m/partfour_imgtwo.png")
      div.partfive
        .title(v-html="$TT('pictThree_title')")
        .sub_title(v-html="$TT('pictThree_sub_title')")
        .comments
          .review.review_one
            .message
              .Headportrait
                img(src="../../assets/img/Headportrait.png")
              .name(v-html="$TT('nameone')")
              .score
                img(src="../../assets/img/score.png")
              .time 2020.12.08
            .details(v-html="$TT('contentone')")
          .review.review_two
            .message
              .Headportrait
                img(src="../../assets/img/Headportraittwo.png")
              .name(v-html="$TT('nametwo')")
              .score
                img(src="../../assets/img/score5.0.png")
              .time 2020.12.08
            .details(v-html="$TT('contenttwo')")
          .review.review_three
            .message
              .Headportrait
                img(src="../../assets/img/Headportraitthree.png")
              .name(v-html="$TT('namethree')")
              .score
                img(src="../../assets/img/score.png")
              .time 2020.12.01
            .details(v-html="$TT('contentthree')")
      .partsix
        .title(v-html="$TT('New quetions')")
        .sub_title(v-html="$TT('See other asked')" @click="seeOther('homepage_otherquestion_click')")
        .card(@click="seeOther('homepage_otherquestion_click')")
          img(src="../../assets/img/m/card.png")
      div.footer(@click="download('download_googleplay_foot_click')")
          div.logo
            img(src="../../assets/img/m/footer_logo.png")
          div.title(v-html="$TT('mforfree')")
          div.google
            img(src="../../assets/img/m/google.png")
</template>
<script>
export default {
  name:'mHome',
  data(){
    return {
      lang:"en",
      command:'Eng',
      downloadUrl: 'https://answered.onelink.me/DJB9/a15710c3',
      isShowInput: false,
      inputValue: ''
    }
  },
  created() {
    this.lang = this.$route.query.lang || 'en'
    this.$setLang(this.lang)
    window._rlog.push(['_trackCustom', 'EVENT', [['lang', this.lang]]])
    window.gtag('event', 'official_homepage_show')
  },
  methods:{
    handleCommand(command){
      window.gtag('event', 'language_switch')
      this.command = command
    },
    download(eventType) {
      window.gtag('event', eventType)
      const a = document.createElement('a')
      a.href = this.downloadUrl
      a.click()
    },
    showInput() {
      this.isShowInput = true
    },
    unShowInput(eventType) {
      if(this.inputValue === '') {
        this.isShowInput = false
      } else {
        window.gtag('event', eventType)
        this.$router.push({
          path: '/m/quesList',
          query: {
            lang: this.lang,
            inputValue: this.inputValue,
          }
        })
      }
    },
    seeOther(eventType) {
      window.gtag('event', eventType)
      this.$router.push({
        path: '/m/quesList',
        query: {
          lang: this.lang,
          inputValue: '',
        }
      })
    }
  },
  watch:{
    command:function(){
        if(this.command == 'Eng' && this.$route.query.lang !== 'en'){
          this.lang = 'en'
          this.$router.push('/m/en')
          this.$setLang(this.lang)
        }else if(this.command == 'Indonesia' && this.$route.query.lang !== 'id') {
          this.lang = 'id'
          this.$router.push('/m/id')
          this.$setLang(this.lang)
        }
    },
    lang:function(){
      window._rlog.push(['_trackCustom', 'EVENT', [['lang', this.lang]]])
      window.gtag('event', 'language_switch')
      if(this.lang == 'en'){
          this.command = 'Eng'
      }else if(this.lang == "id"){
        this.command = "Indonesia"
      }
    },
  },
}
</script>
<style lang="stylus">
.section
  font-family: Roboto;
.partone
  overflow hidden
  background: linear-gradient(180deg, #FFF0E6 0%, rgba(255, 255, 255, 0) 100%);
  .header
    display:flex
    align-items:center
    margin:0.4rem 0.4rem 0 0.4rem
    position:relative
    .logo_area
      display:flex
      align-items:center
      .img_area
        width:0.64rem
        height 0.64rem
        margin-right:0.16rem
        img
          width:100%
      .title
        font-weight: bold;
        font-size: 0.26rem;
        color: #111111;
    .area-right
      position absolute
      right 0
      .lanSwitch
        display inline-block
        vertical-align middle
        margin-right 0.18rem
        .el-dropdown-link
          font-size: 0.26rem;
          color: #1E1E20;
      .search-icon
        width 0.325rem
        height 0.325rem
        display inline-block
        vertical-align middle
        img
          width 100%
      .input-area
        position relative
        display inline-block
        vertical-align middle
        .input
          background #F3F3F3
          border-radius 0.12rem
          outline none
          font-family:Roboto-Regular;
          font-size 0.26rem
          width 2.28rem
          height 0.8rem
          line-height 0.3rem
          color #A0A0A0
          padding-left 0.53rem
        .input-search_icon
          width 0.24rem
          height 0.24rem
          position absolute
          top 0.28rem
          left 0.2025rem
  .content
    width:7rem
    margin:1.16rem auto 0
    text-align:center
    .title
      font-family:Roboto-Black;
      font-weight: 900;
      font-size: 0.66rem;
      line-height:0.72rem;
      color: #111100;
      span
        letter-spacing: 0.025rem;
        font-weight: 300;
        font-family:Roboto-Light;
    .sub
      color:#FF6B08
      letter-spacing: 0.017rem;
    .details
      width:6.98rem
      margin-top:0.24rem
      font-size: 0.26rem;
      font-family:Roboto-Regular;
      line-height: 0.30rem;
      letter-spacing: 0.001rem;
      color: #111100;
    .google
      width:2.8rem
      height:0.83rem
      margin:0.64rem auto 0
      img
        width:100%
    .partone_img
      height:10.1rem
      margin:1rem 1.065rem 1rem
      background: url("../../assets/img/m/en/partone_img.png") 0 0 / 100%
.parttwo
  height:4.2rem
  background url("../../assets/img/m//partTwoBg.png") 0 0 / 100%
  overflow hidden
  .content
    height:3.58rem
    margin:0.31rem 0.4rem
    overflow hidden
    background: url("../../assets/img/m//scanning.png") 0 0 / 100%
    .title
      font-weight: 900;
      font-size: 0.34rem;
      line-height: 0.4rem;
      text-align center
      font-family:Roboto-Black;
      color: #FFFFFF;
      margin:0.71rem auto 0.16rem
    .sub_title
      font-size: 0.26rem;
      font-family:Roboto-Regular;
      line-height: 0.3rem;
      text-align center
      letter-spacing: 0.001rem;
      color: #FFFFFF;
      margin:0 auto 0.25rem
      // opacity:0.7
    .details
      margin:0 auto
      display:flex
      align-items:center
      justify-content center
      .number
        font-size: 0.72rem;
        font-family:Roboto-Black;
        font-weight: bold;
        line-height: 0.84rem;
        letter-spacing: 0.0012468rem;
        color: #FF6B08;
        margin-right:0.29rem
      .smalltriangle
        width:0.27rem
        margin-right:0.25rem
        .spac2
          position relative
          top:-0.2rem
        .spac1
          position relative
          top:0.1rem
        img
          width:100%
      .questions
        font-size: 0.26rem;
        font-family:Roboto-Bold;
        line-height: 0.3rem;
        letter-spacing: 0.000498718rem;
        color: #FF6B08;
.partthree
  height:12.46rem
  background-color:#F5FCFC;
  overflow hidden
  position relative
  .title
    font-weight: 900;
    font-family:Roboto-Black;
    font-size: 0.48rem;
    line-height: 0.55rem;
    text-align: center;
    color: #111100;
    margin:0.8rem auto 0.24rem
    span
      color: #FF6B08;
  .sub_title
    width:6.7rem
    font-family:Roboto-Regular;
    font-size: 0.26rem;
    line-height: 0.36rem;
    text-align: center;
    letter-spacing: 0.001rem;
    color: #111111;
    margin:0 auto 0.64rem
  .partthree_img
    margin:0 0.4rem
    font-size:0
    img
      width:100%
  .partThreePeople
    width:2.53rem
    height:2.15rem
    position absolute
    right:0
    bottom:0
    img
      width:100%
.partfour
  background-color:#F0F6FF;
  overflow hidden
  .title
    width:5.2rem
    font-family:Roboto-Black;
    font-weight: 900;
    font-size: 0.48rem;
    line-height: 0.56rem;
    text-align: center;
    color: #111100;
    margin:0.8rem auto 0
    span
      color:#FF6B08;
  .sub_title
    width:6.6rem
    font-family:Roboto-Regular;
    font-size: 0.26rem;
    line-height: 0.36rem;
    text-align: center;
    letter-spacing: 0.001rem;
    color: #111111;
    margin:0.24rem auto 0
  .partfour_img
    position relative
    .partfour_imgone
      margin:0.74rem 1.36rem 1.2rem
      img
        width:100%
    .partfour_imgtwo
      margin:0 0.95rem
      position absolute
      top:2.69rem
      img
        width:100%
.partfive
  margin-bottom:0.96rem
  overflow hidden
  .title
    font-weight: 900;
    font-family:Roboto-Black;
    font-size: 0.48rem;
    line-height: 0.5rem;
    text-align: center;
    color: #111100;
    margin-top:0.8rem
    span
      color:#FF6B08;
  .sub_title
    font-size: 0.26rem;
    font-family:Roboto-Regular;
    line-height: 0.36rem;
    text-align: center;
    letter-spacing: 0.001rem;
    color: #111111;
    margin-top:0.24rem
  .comments
    margin:0.64rem 0.4rem 0
    .review_one
      background-color #FFFBFB;
      border: 1px solid #FADEDE;
      box-sizing: border-box;
      border-radius: 0.24rem;
    .review_two
      background: #FEFFFA;
      border: 0.01rem solid #DDE8B3;
      box-sizing: border-box;
      border-radius: 0.24rem;
    .review_three
      background: #FFFCFA;
      border: 0.01rem solid #E8CCB3;
      box-sizing: border-box;
      border-radius: 0.24rem;
    .review
      margin-bottom:0.24rem
      .message
        margin:0.34rem 0.34rem 0
        position relative
        .Headportrait
          width:0.79rem
          height 0.79rem
          img
            width:100%
        .name
          font-weight: bold;
          font-family:Roboto-Bold;
          font-size: 0.26rem;
          line-height: 0.30rem;
          color: #111100;
          position absolute
          left:1.03rem
          top:0.07rem
        .score
          position absolute
          left 1.03rem
          top: 0.4rem
          width:1.35rem
          img
            width:100%
        .time
          position absolute
          right:0
          top:0.15rem
          font-size: 0.18rem;
          line-height: 0.21rem;
          color: #D8D8D8;
      .details
        font-size: 0.24rem;
        font-family:Roboto-Regular;
        line-height: 0.36rem;
        color: #111100;
        margin:0.32rem 0.34rem 0.4rem
.partsix
  background #F8FAFD
  overflow hidden
  margin-bottom 1.3rem
  .title
    font-weight 900
    font-family Roboto-Black
    font-size 0.48rem
    line-height 0.56rem
    text-align center
    margin 0.81rem auto 0.24rem
    span
      color #FF6B08
  .sub_title
    width 3.84rem
    height 0.72rem
    border 0.02rem solid #FF6B08
    font-family Roboto
    font-weight 700
    border-radius 0.12rem
    font-size 0.28rem
    line-height 0.72rem
    color #FF6B08
    text-align center
    margin 0 auto 0.64rem
  .card
    width 6.23rem
    height 4.04rem
    margin 0 auto 0.8rem
    img
      width 100%
.footer
  overflow hidden
  width 100%
  height:1.3rem
  position fixed
  bottom 0
  background :url(../../assets/img/m/footerbg.png) 0 0 / 100%
  .logo
    width:0.6rem
    height:0.6rem
    display inline-block
    position absolute
    top 0.35rem
    left 0.6rem
    border:0.001rem soild #FFFFFF
    img
      width:100%
  .title
    width:3.8rem
    font-family:Roboto-Black;
    display inline-block
    font-weight: 900;
    font-size: 0.3rem;
    line-height: 0.35rem;
    text-align: left;
    color: #FFFFFF;
    position absolute
    top 0.33rem
    left 1.41rem
    span
      color:#FF6B08;
  .google
    width 1.66rem
    height 0.4919rem
    display inline-block
    position absolute
    top 0.4rem
    right 0.6rem
    img
      width:100%

.id
  .partone
    .content
      .partone_img
        background:url(../../assets/img/m/id/partone_img.png) 0 0 / 100% no-repeat
  .parttwo
    .content
      .sub_title
        font-size: 0.22rem;

</style>